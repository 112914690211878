import React, { useContext, useEffect, useRef, useState } from "react";
import video from "../assets/video/introVideo.mp4";
import { PopupContext } from "../contexts/PopupContext";
import "../assets/css/FloatingVideoPlayer.css";

const FloatingVideoPlayer = () => {
    const videoRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({
        x: window.innerWidth - 360, // Set initial position to the right end
        y: window.innerHeight * 0.30, // Initial y position as a percentage of innerHeight
    });
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    const { isPopupVisible, setIsPopupVisible } = useContext(PopupContext);

    useEffect(() => {
        // Dynamically update the value of x and y based on screen width and height
        const getDynamicValues = () => {
            let xValue, yValue;

            if (window.innerWidth <= 480) {
                // Mobile screen size
                console.log('480');
                xValue = window.innerWidth - 260; // Example for mobile
                yValue = window.innerHeight * 0.3; // Adjust y as percentage of height
            } else if (window.innerWidth <= 600) {
                // Slightly larger mobile sizes (up to 600px)
                console.log('600');
                xValue = window.innerWidth - 360; // Example for larger mobile
                yValue = window.innerHeight * 0.3; // Adjust y for larger mobile
            } else if (window.innerWidth <= 774) {
                // Tablet screen size
                console.log('768');
                xValue = window.innerWidth - 500; // Example for tablet
                yValue = window.innerHeight * 0.20; // Adjust y for tablet
            } else {
                // Desktop screen size
                xValue = window.innerWidth - 360; // Default for larger screens
                yValue = window.innerHeight * 0.23; // Adjust y for desktop
            }

            return { xValue, yValue };
        };

        // Update position with dynamic values
        const { xValue, yValue } = getDynamicValues();
        setPosition(prevPosition => ({
            ...prevPosition,
            x: xValue,
            y: yValue,
        }));

    }, [window.innerWidth, window.innerHeight]); // Update on window resize

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const popupWidth = document.querySelector(".video-popup").offsetWidth;
            setPosition({
                x: Math.max(0, Math.min(window.innerWidth - popupWidth, e.clientX - dragStart.x)),
                y: Math.max(0, Math.min(window.innerHeight - 180, e.clientY - dragStart.y)),
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        setIsDragging(true);
        setDragStart({ x: touch.clientX - position.x, y: touch.clientY - position.y });
    };

    const handleTouchMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const touch = e.touches[0];
            const popupWidth = document.querySelector(".video-popup").offsetWidth;
            setPosition({
                x: Math.max(0, Math.min(window.innerWidth - popupWidth, touch.clientX - dragStart.x)),
                y: Math.max(0, Math.min(window.innerHeight - 180, touch.clientY - dragStart.y)),
            });
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("touchmove", handleTouchMove, { passive: false });
        window.addEventListener("touchend", handleTouchEnd);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("touchmove", handleTouchMove);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    }, [isDragging, dragStart]);

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <>
            {isPopupVisible && (
                <div
                    className="video-popup"
                    style={{ top: `${position.y}px`, left: `${position.x}px` }}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                >
                    <div className="btn-div w-100 pb-2 text-end">
                        <button className="close-button" onClick={handleClosePopup}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </button>
                    </div>
                    <video ref={videoRef} controls autoPlay muted playsInline>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <button
                        onClick={() =>
                            window.open("https://www.youtube.com/@themoshowatzogi", "_blank")
                        }
                        className="watch-button"
                    >
                        Watch More
                    </button>
                </div>
            )}
        </>
    );
};

export default FloatingVideoPlayer;
