import React, { createContext, useState } from "react";

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    return (
        <PopupContext.Provider value={{ isPopupVisible, setIsPopupVisible }}>
            {children}
        </PopupContext.Provider>
    );
};
