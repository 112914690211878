import React from 'react'
import { Route, Router, Routes } from 'react-router-dom'
import About from '../pages/About'
import Microfactories from '../pages/Microfactories'
import ContactUs from '../pages/ContactUs'
import SpecialEvents from '../pages/SpecialEvents'
import Testpage from '../pages/Testpage'
import Campains from '../pages/Campains'
import Nav from './Nav'
import Footer from './Footer'
import Partners from '../pages/Partners'
import Imprint from '../pages/Imprint'
import DataProtection from '../pages/DataProtection'
import TermsConditions from '../pages/TermsConditions'
import ShopNow from '../pages/ShopNow'
import HomeFooter from './HomeFooter'
import Home from '../pages/Home'
import VideoPopup from './VideoPopup'

export default function PaegeRoutes() {
  return (
    <>
      {/* <VideoPopup /> */}
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/microfactories" element={<Microfactories />} />
        <Route path='/special_events' element={<SpecialEvents />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/campains' element={<Campains />} />
        <Route path='/imprint' element={<Imprint />} />
        <Route path='/data_protection' element={<DataProtection />} />
        <Route path='/terms' element={<TermsConditions />} />
        <Route path='/shop' element={<ShopNow />} />
        <Route path="*" element={<h2>Page not found</h2>} />

      </Routes>
      <HomeFooter />
    </>
  )
}
